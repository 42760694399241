<template>
  <div>
    <span v-if="text">
      {{ isTrue ? $t(trueText) : $t(falseText) }}
    </span>
    <v-icon
      v-else
      :color="
        color != null ? color : colored ? (isTrue ? 'success' : 'error') : ''
      "
      :small="small"
      :x-small="xSmall"
      :large="large"
      :x-large="xLarge"
      :dense="dense"
      :dark="dark"
      :light="light"
      :left="left"
      :right="right"
      :size="size"
    >
      {{ isTrue ? trueIcon : falseIcon }}
    </v-icon>
  </div>
</template>

<script>
export default {
  name: "BooleanValue",
  props: {
    value: {
      required: true,
    },
    text: {
      type: Boolean,
      default: false,
    },
    colored: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
    trueIcon: {
      type: String,
      default: "mdi-check-circle-outline",
    },
    falseIcon: {
      type: String,
      default: "mdi-close-circle-outline",
    },
    trueText: {
      type: String,
      default: "yes",
    },
    falseText: {
      type: String,
      default: "no",
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [Number, String],
      default: undefined,
    },
  },
  computed: {
    isTrue() {
      if (this.value == null) return false;
      else if (typeof this.value === "boolean") return this.value;
      else if (
        typeof this.value === "string" &&
        ["0", "n", "no", "off", "not", "disabled", "none", "nein", ""].includes(
          this.value.toLowerCase()
        )
      )
        return false;
      else return Boolean(this.value);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "yes": "yes",
    "no": "no"
  }, 
  "de": {
   "yes": "ja",
   "no": "nein"
  }
}
</i18n>
