<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("BBCS order status") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :disabled="loading"
            :loading="loading"
            @click="getOrderStatus()"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload status") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="$emit('closeOrderStatus')" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("close") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-progress-linear :active="loading" indeterminate></v-progress-linear>
    <v-card-text class="pt-2">
      <v-simple-table v-if="status != null">
        <template v-slot:default>
          <tbody>
            <tr>
              <th>{{ $t("order number") }}</th>
              <td>{{ orderNumber }}</td>
            </tr>
            <tr v-if="status.state">
              <th>{{ $t("state") }}</th>
              <td>
                <v-chip>{{ status.state }}</v-chip>
              </td>
            </tr>
            <tr v-if="status.base_state">
              <th>{{ $t("base status") }}</th>
              <td>
                <v-chip>{{ status.base_state }}</v-chip>
              </td>
            </tr>
            <tr v-if="status.base_type">
              <th>{{ $t("base type") }}</th>
              <td>
                <v-chip>{{ status.base_type }}</v-chip>
              </td>
            </tr>
            <tr v-if="status.bbtype">
              <th>{{ $t("broadband type") }}</th>
              <td>
                <v-chip>{{ status.bbtype }}</v-chip>
              </td>
            </tr>
            <tr v-if="status.dn">
              <th>{{ $t("domain number") }}</th>
              <td>{{ status.dn }}</td>
            </tr>
            <tr v-if="status.dntype">
              <th>{{ $t("domain type") }}</th>
              <td>
                <v-chip>{{ status.dntype }}</v-chip>
              </td>
            </tr>
            <tr v-if="status.expected_completion">
              <th>{{ $t("expected completion") }}</th>
              <td>{{ formatDateTime(status.expected_completion) }}</td>
            </tr>
            <tr v-if="status.expected_completion_timewindow">
              <th>{{ $t("expected completion time window") }}</th>
              <td>{{ status.expected_completion_timewindow }}</td>
            </tr>

            <tr v-if="status.last_check">
              <th>{{ $t("last check") }}</th>
              <td>{{ formatDateTime(status.last_check) }}</td>
            </tr>
            <tr v-if="status.last_update">
              <th>{{ $t("last update") }}</th>
              <td>{{ formatDateTime(status.last_update) }}</td>
            </tr>
            <tr v-if="status.last_trx">
              <th>{{ $t("last transaction") }}</th>
              <td>{{ formatDateTime(status.last_trx) }}</td>
            </tr>
            <tr v-if="status.last_message">
              <th>{{ $t("last message") }}</th>
              <td>{{ status.last_message }}</td>
            </tr>
            <tr>
              <th>{{ $t("missing disconnect") }}</th>
              <td v-if="status.missing_disconnect">
                <table class="missing-disconnect">
                  <tr>
                    <td>{{ $t("disconnect missing") }}</td>
                    <td>
                      <v-chip>
                        {{
                          status.missing_disconnect.disconnect_missing
                            ? $t("yes")
                            : $t("no")
                        }}
                      </v-chip>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("disconnect needed") }}</td>
                    <td>
                      <v-chip>
                        {{
                          status.missing_disconnect.disconnect_needed
                            ? $t("yes")
                            : $t("no")
                        }}
                      </v-chip>
                    </td>
                  </tr>
                  <tr v-if="status.missing_disconnect.disconnect_date">
                    <td>{{ $t("disconnect date") }}</td>
                    <td>
                      {{
                        formatDate(status.missing_disconnect.disconnect_date)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("NSN") }}</td>
                    <td>{{ status.missing_disconnect.nsn }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("ISP ID") }}</td>
                    <td>{{ status.missing_disconnect.isp_id }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("ISP name") }}</td>
                    <td>{{ status.missing_disconnect.isp_name }}</td>
                  </tr>
                </table>
              </td>
              <td v-else="status.missing_disconnect">
                {{ $t("no") }}
              </td>
            </tr>
            <tr v-if="status.flags">
              <th>{{ $t("flags") }}</th>
              <td>
                <template v-for="(index, name) in status.flags">
                  <v-chip v-if="status.flags[name]" class="ma-1">
                    {{ name }}
                  </v-chip>
                </template>
              </td>
            </tr>
            <tr v-if="status.base_history">
              <th>{{ $t("history") }}</th>
              <td>
                <table class="history">
                  <tr>
                    <th>{{ $t("state") }}</th>
                    <th>{{ $t("eta start") }}</th>
                    <th>{{ $t("eta end") }}</th>
                    <th>{{ $t("cwd") }}</th>
                    <th>{{ $t("reason") }}</th>
                  </tr>
                  <tr
                    v-for="(entry, index) in status.base_history"
                    :key="index"
                  >
                    <td>
                      <v-chip x-small>{{ entry.state }}</v-chip>
                    </td>
                    <td>
                      {{
                        entry.eta_start ? formatDateTime(entry.eta_start) : ""
                      }}
                    </td>
                    <td>
                      {{ entry.eta_end ? formatDateTime(entry.eta_end) : "" }}
                    </td>
                    <td>{{ entry.cwd }}</td>
                    <td>{{ entry.reason }}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text :disabled="loading" @click="$emit('closeOrderStatus')">
        {{ $t("close") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import showErrors from "@/utils/mixins/showErrors";
import formatDateTime from "@/utils/mixins/formatDateTime";
import formatDate from "@/utils/mixins/formatDate";
import BooleanValue from "@/components/basics/BooleanValue";

export default {
  name: "BbcsOrderStatus",
  props: {
    orderNumber: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    BooleanValue,
  },
  mixins: [formatDateTime, formatDate, showErrors],
  data: () => ({
    loading: false,
    status: null,
  }),
  watch: {
    orderNumber: {
      handler() {
        this.getOrderStatus();
      },
    },
  },
  methods: {
    getOrderStatus() {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/bbcs/orderstatus", {
          params: { order_number: this.orderNumber },
        })
        .then((response) => {
          that.status = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.getOrderStatus();
  },
};
</script>

<i18n>
{
  "en": {
    "BBCS order status": "BBCS order status",
    "close": "Close",
    "reload status": "reload status",
    "order number": "Order Number",
    "state": "State",
    "base status": "Base Status",
    "base type": "Base Type",
    "broadband type": "Broadband Type",
    "domain number": "Domain Number",
    "domain type": "Domain Type",
    "expected completion": "Expected Completion",
    "expected completion time window": "Expected Completion Time Window",
    "last check": "Last Check",
    "last update": "Last Update",
    "last transaction": "Last Transaction",
    "last message": "Last Message",
    "flags": "Flags",
    "missing disconnect": "Missing Disconnect",
    "NSN": "NSN",
    "ISP ID": "ISP ID",
    "ISP name": "ISP Name",
    "disconnect needed": "Disconnect Needed",
    "disconnect missing": "Disconnect Missing",
    "disconnect date": "Disconnect Date",
    "history": "History",
    "eta start": "Estimated Time of Arrival Start",
    "eta end": "Estimated Time of Arrival End",
    "cwd": "Customer Wishdate",
    "reason": "Reason",
    "no": "no",
    "yes": "yes"
  },
  "de": {
    "BBCS order status": "BBCS Bestellstatus",
    "close": "Schliessen",
    "reload status": "Status aktualisieren",
    "order number": "Bestellnummer",
    "state": "Status",
    "base status": "Basis Status",
    "base type": "Basis Typ",
    "broadband type": "Breitband Typ",
    "domain number": "Domain Nummer",
    "domain type": "Domain Typ",
    "expected completion": "Erwartete Fertigstellung",
    "expected completion time window": "Zeitfenster der erwarteten Fertigstellung",
    "last check": "Letzter Check",
    "last update": "Letzter Aktualisierung",
    "last transaction": "Letzte Bearbeitung",
    "last message": "Letzte Nachricht",
    "flags": "Flags",
    "missing disconnect": "Missing Disconnect",
    "NSN": "NSN",
    "ISP ID": "ISP ID",
    "ISP name": "ISP Name",
    "disconnect needed": "Disconnect Needed",
    "disconnect missing": "Disconnect Missing",
    "disconnect date": "Disconnect Date",
    "history": "History",
    "eta start": "Voraussichtliche Ankunftszeit Start",
    "eta end": "Voraussichtliche Ankunftszeit Ende",
    "cwd": "Kunden Wunschdatum",
    "reason": "Grund",
    "no": "nein",
    "yes": "ja"
  }
}
</i18n>

<style scoped>
table.history {
  margin: 25px 0;
  font-size: 0.8em;
  background: none;
}
table.history tr td {
  padding: 12px 15px;
  margin: 20px 20px;
  text-align: center;
}
table.history tr th {
  padding: 12px 15px;
  margin: 20px 20px;
  text-align: center;
}
table.missing-disconnect {
  margin: 20px 0;
  background: none;
}
table.missing-disconnect tr td {
  padding: 4px 4px;
}
</style>
